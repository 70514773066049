<template>
  <div>
    <p class="post-description">
      <span v-if="!seeMore" @click="handleFireAction">
        {{ text.substring(0, numberOfSlice) }}
        <span v-if="text.length > numberOfSlice"> ... </span>
      </span>
      <span v-else @click="handleFireAction">
        {{ text }}
      </span>
      <a class="btn-read" v-if="text.length > numberOfSlice" @click="handleSeeMore">
        <span v-if="seeMore"> ({{ $t("GLOBAL_SEE_LESS") }}) </span>
        <span v-else>({{ $t("GLOBAL_SEE_MORE") }} )</span>
      </a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    numberOfSlice: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      seeMore: false,
    };
  },
  methods: {
    handleSeeMore() {
      this.seeMore = !this.seeMore;
    },
    handleFireAction() {
      this.$emit("handleFireAction");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
