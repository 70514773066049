<template>
  <b-modal id="add-comment-modal" size="lg" hide-footer @hide="closeModal">
    <template v-slot:modal-header="{ close }">
      <h6 class="mb-0">
        {{ $t("LEADERS.ADD_COMMENT") }}
      </h6>
      <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
    </template>

    <div class="modal-body" :class="$i18n.locale == 'en' ? '' : 'rtl'">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formAddComment">
          <div v-if="post">
            <div class="post-card">
              <div class="mr-3">
                <image-circle
                  :defaultImage="post.commentator ? post.commentator.image_full_path : null"
                />
              </div>
              <div class="w-100">
                <h4 class="post-name">
                  {{ post.commentator ? post.commentator.name : "" }}
                </h4>
                <h5 class="post-time">{{ post.readable_created_at }}</h5>
              </div>
            </div>
            <p class="post-description">
              {{ post.original_text }}
            </p>
          </div>

          <div class="d-flex" v-if="user">
            <div class="mr-3">
              <image-circle :defaultImage="user.image" />
            </div>
            <div class="w-100">
              <TextAreaField
                v-model="comment"
                rows="4"
                no-resize
                class="comment-text"
                :placeholder="$t('LEADERS.WRITE_COMMENT')"
              >
                <div class="btn-send">
                  <Button :loading="loading" :disabled="!comment" type="submit">
                    <b-icon icon="cursor-fill"></b-icon>
                  </Button>
                </div>
              </TextAreaField>
            </div>
          </div>
        </b-form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
import ImageCircle from "@/components/Shared/ImageCircle";
import { mapGetters } from "vuex";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ImageCircle,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      comment: null,
    };
  },
  methods: {
    onSubmit() {
      this.$emit("handleAddComment", this.comment);
    },
    closeModal() {
      this.$emit("closeModal");
      this.$refs.observer.reset();
      this.comment = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
