<template>
  <div v-if="post">
    <div class="post-card">
      <div class="mr-3">
        <image-circle :defaultImage="post.commentator ? post.commentator.image_full_path : null" />
      </div>
      <div class="w-100">
        <h4 class="post-name">
          {{ post.commentator ? post.commentator.name : "" }}
        </h4>
        <h5 class="post-time">{{ post.readable_created_at }}</h5>
      </div>
    </div>
    <!-- @handleFireAction="handleShowAllComments" -->
    <show-more :text="post.original_text" :numberOfSlice="numberOfSlice" />
    <div class="post-footer" v-if="showActions">
      <emojis @handleSelectEmoji="handleSelectEmoji" />
      <button
        v-if="showComment"
        class="btn btn-hover footer-title d-flex align-items-center"
        @click="handleOpenAddCommentModal"
      >
        <b-icon class="post-icon" icon="chat-right"></b-icon>
        {{ $t("COMMENT") }}
      </button>

      <div
        v-if="post.nestedComments && post.nestedComments.data.length"
        class="footer-title comments pointer"
        @click="handleShowAllComments"
      >
        {{ post.nestedComments.data.length }}
        <span>
          {{ $t("COMMENTS") }}
        </span>
      </div>
      <div class="d-flex align-items-center">
        <div v-for="(reaction, index) in post.reactions_summary" :key="index" class="reaction-btn">
          <span>
            {{ reaction.reaction }}
            {{ reaction.count }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCircle from "@/components/Shared/ImageCircle";
import ShowMore from "@/components/Shared/ShowMore";
import Emojis from "@/components/Application/Leaders/Emojis";
export default {
  props: {
    post: {
      type: Object,
      default: () => {},
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    showComment: {
      type: Boolean,
      default: true,
    },
    numberOfSlice: {
      type: Number,
      default: 300,
    },
  },
  components: {
    ImageCircle,
    ShowMore,
    Emojis,
  },

  data() {
    return {};
  },

  methods: {
    handleOpenAddCommentModal() {
      this.$emit("handleOpenAddCommentModal", this.post);
    },
    handleShowAllComments() {
      const data = {
        post: this.post,
        show: true,
      };
      this.$emit("handleShowAllComments", data);
    },

    handleSelectEmoji(reaction) {
      const body = {
        id: this.post.id,
        reaction,
      };
      this.$emit("handleSelectEmoji", body);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
