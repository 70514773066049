<template>
  <div v-if="post" class="main-post-container">
    <div class="back-header">
      <button class="btn back-link btn-text" @click="handleBack">
        <b-icon
          class="back-icon"
          :icon="$i18n.locale == 'ar' ? 'arrow-right' : 'arrow-left'"
        ></b-icon>
        {{ $t(backTitle) }}
      </button>
    </div>

    <div class="post-container">
      <post-card
        :post="post"
        @handleOpenAddCommentModal="handleOpenAddCommentModal"
        @handleSelectEmoji="handleSelectEmoji"
      />
    </div>

    <div class="comments-container" v-if="post.nestedComments && post.nestedComments.data.length">
      <div class="post-card" v-for="(comment, index) in post.nestedComments.data" :key="index">
        <div class="post-container">
          <div class="post-photo">
            <image-circle
              :defaultImage="comment.commentator ? comment.commentator.image_full_path : null"
            />
          </div>
          <div class="comment-body">
            <h4 class="post-name">
              {{ comment.commentator ? comment.commentator.name : "" }}
              <span class="post-time">
                {{ comment.readable_created_at }}
              </span>
            </h4>
            <show-more :text="comment.original_text" :numberOfSlice="numberOfSlice" />

            <div class="post-footer" v-if="showActions">
              <emojis @handleSelectEmoji="handleSelectCommentEmoji($event, comment)" />

              <!-- <button
                class="btn btn-hover footer-title d-flex align-items-center"
                @click="handleOpenAddCommentModal"
              >
                <b-icon class="post-icon" icon="chat-right"></b-icon>
                {{ $t("COMMENT") }}
              </button> -->

              <!-- <div class="footer-title comments">
                {{ post.nestedComments.data.length }}
                <span>
                  {{ $t("COMMENTS") }}
                </span>
              </div> -->
              <div class="d-flex align-items-center">
                <div
                  v-for="(reaction, index) in comment.reactions_summary"
                  :key="index"
                  class="reaction-btn footer-title"
                >
                  <span>
                    {{ reaction.reaction }}
                    {{ reaction.count }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCircle from "@/components/Shared/ImageCircle";
import ShowMore from "@/components/Shared/ShowMore";
import PostCard from "@/components/Application/Leaders/PostCard";
import Emojis from "@/components/Application/Leaders/Emojis";

export default {
  props: {
    post: {
      type: Object,
      default: () => {},
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    numberOfSlice: {
      type: Number,
      default: 300,
    },
    backTitle: {
      type: String,
      default: "LEADERS.POSTS",
    },
  },
  components: {
    ImageCircle,
    ShowMore,
    PostCard,
    Emojis,
  },

  methods: {
    handleOpenAddCommentModal() {
      this.$emit("handleOpenAddCommentModal", this.post);
    },
    handleBack() {
      this.$emit("handleBack");
    },

    handleSelectEmoji(body) {
      this.$emit("handleSelectEmoji", body);
    },
    handleSelectCommentEmoji(reaction, comment) {
      const body = {
        id: comment.id,
        reaction,
      };
      this.$emit("handleSelectEmoji", body);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
