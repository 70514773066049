<template>
  <b-modal id="leader-follow-modal" hide-footer size="lg" @hide="closeModal" class="leader-follow">
    <template v-slot:modal-header="{ close }">
      <h6 class="mb-0">
        {{ $t("LEADERS.FOLLOWING") }}
      </h6>
      <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
    </template>

    <div class="modal-body" :class="$i18n.locale == 'en' ? '' : 'rtl'">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" v-if="leader">
        <div class="profile-container" v-if="leader">
          <div class="mr-3">
            <image-circle
              :defaultImage="leader.image"
              :width="74"
              :height="74"
              :iconHeight="54"
              :iconWidth="54"
            />
          </div>

          <div class="profile-details">
            <ul>
              <li class="name mr-2">{{ leader.name }}</li>
              <li class="followers mr-2">
                {{ leader.followers_count }}
                <span> {{ $t("LEADERS.FOLLOWERS") }} </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="following-meaning" v-if="showFollowing">
          <div class="d-flex justify-content-between">
            <h4>{{ $t("LEADERS.FOLLOWING_MEANING") }}</h4>
            <b-icon
              class="close-following"
              icon="x"
              @click="showFollowing = !showFollowing"
            ></b-icon>
          </div>
          <!-- content -->
          <p>
            {{ $t("LEADERS.FOLLOWING_MEANING_MEESAGE") }}
          </p>
        </div>
        <div class="stop-following">
          <h4>
            {{ $t("LEADERS.STOP_FOLLOWWING") }}
          </h4>
        </div>

        <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formLeaderFollow">
          <div class="row leader-form">
            <div class="col-lg-6">
              <b-form-group>
                <b-input-group class="align-items-start flex-nowrap">
                  <template #append>
                    <span class="input-group-text following-value">$</span>
                  </template>

                  <TextField
                    v-model="form.followingValue"
                    rules="required|numeric"
                    :placeholder="$t('LEADERS.ENTER_VALUE')"
                    :name="$t('LEADERS.VALUE')"
                    class="w-100"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <SelectField
                  v-model="form.converAssetsTo"
                  :label="$t('LEADERS.CONVERT_ALL')"
                  :name="$t('LEADERS.CONVERT_ALL')"
                  :options="options"
                  text-field="name"
                  value-field="value"
                  rules="required"
                />
              </b-form-group>
            </div>
          </div>

          <b-form-group>
            <CheckboxField
              class="accept"
              v-model="underStandModal"
              :name="$t('LEADERS.UNDERSTAND')"
              :checked="underStandModal"
              rules="required"
            />
          </b-form-group>

          <b-form-group class="d-flex justify-content-end">
            <Button
              type="button"
              color="light-dark"
              customClass="text-uppercase mr-3 font-14"
              @click="$bvModal.hide('leader-follow-modal')"
            >
              {{ $t("GLOBAL_CANCEL") }}
            </Button>
            <Button
              type="submit"
              color="primary"
              customClass="text-uppercase font-14"
              :loading="loading"
              :disabled="!underStandModal"
            >
              {{ $t("LEADERS.FOLLOW") }}
            </Button>
          </b-form-group>
        </b-form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
import ImageCircle from "@/components/Shared/ImageCircle";

export default {
  props: {
    leader: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImageCircle,
  },
  data() {
    return {
      options: [{ value: "USDT", name: "USDT" }],
      underStandModal: false,
      showFollowing: true,
      form: {
        followingValue: null,
        converAssetsTo: null,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.$refs.observer.reset();
      this.underStandModal = false;
      this.showFollowing = true;
      this.form = {
        followingValue: null,
        converAssetsTo: null,
      };
    },
    onSubmit() {
      this.$emit("handleFollow", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
