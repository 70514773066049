<template>
  <div>
    <div class="main-emoji-container">
      <div class="emoji-container">
        <button
          v-for="(emoji, index) in emojis"
          :key="index"
          class="btn btn-emoji"
          @click="handleSelectEmoji(emoji)"
        >
          {{ emoji.icon }}
        </button>
      </div>

      <button class="btn btn-hover btn-show-emoji footer-title" @click="handleToggleEmoji">
        <span class="icon">
          {{ selectedEmoji.icon }}
        </span>
        <span>
          {{ selectedEmoji.name }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showEmoji: false,
      selectedEmoji: {
        name: this.$t("EMOJI.LIKE"),
        value: "like",
        icon: "👍",
      },
      emojis: [
        {
          name: this.$t("EMOJI.LIKE"),
          value: "like",
          icon: "👍",
        },
        {
          name: this.$t("EMOJI.EYES"),
          value: "eyes",
          icon: "👀",
        },
        {
          name: this.$t("EMOJI.HEART"),
          value: "heart_eyes",
          icon: "😍",
        },
        {
          name: this.$t("EMOJI.PARTY"),
          value: "party",
          icon: "🥳",
        },
      ],
    };
  },
  methods: {
    handleToggleEmoji() {
      this.showEmoji = !this.showEmoji;
    },
    handleSelectEmoji(emoji) {
      this.showEmoji = false;
      this.selectedEmoji = emoji;
      this.$emit("handleSelectEmoji", emoji.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./";
</style>
